<template>
  <base-layout title="Dashboard">
    <ion-row>
      <ion-col size="6">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>Current Session</ion-card-subtitle>
            <ion-card-title v-if="activeSession">
              {{ activeSession.name }}
            </ion-card-title>
          </ion-card-header>
        </ion-card>
      </ion-col>
      <ion-col size="6">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>Classrooms</ion-card-subtitle>
            <ion-card-title> {{ classes.total }} </ion-card-title>
          </ion-card-header>
        </ion-card>
      </ion-col>
      <ion-col size="6">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>Teachers</ion-card-subtitle>
            <ion-card-title>{{ teachers.total - 1 }}</ion-card-title>
          </ion-card-header>
        </ion-card>
      </ion-col>
      <ion-col size="6">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>Students</ion-card-subtitle>
            <ion-card-title>{{ students.total }}</ion-card-title>
          </ion-card-header>
        </ion-card>
      </ion-col>

      <ion-col size="12" class="ion-margin-vertical">
        <div>
          <canvas id="attendance-chart"></canvas>
        </div>
      </ion-col>

      <ion-col size="12" class="ion-margin-vertical">
        <div>
          <canvas id="invoice-chart"></canvas>
        </div>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);
import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/vue";

export default {
  components: {
    BaseLayout,

    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
  },

  data() {
    return {
      attendanceChartData: null,
      invoiceChartData: null,
    };
  },

  computed: {
    userData() {
      return this.$store.getters["user/user"];
    },

    sessions() {
      return this.$store.getters["session/resources"];
    },
    classes() {
      return this.$store.getters["class/resources"];
    },
    teachers() {
      return this.$store.getters["teacher/resources"];
    },
    students() {
      return this.$store.getters["student/resources"];
    },

    activeSession() {
      const sessions = this.sessions.data;
      const active_session = sessions.find((item) => item.is_active === true);
      return active_session;
    },

    allInvoiceStat() {
      return this.$store.getters["invoice/allInvoiceStat"];
    },

    allAttendanceRecord() {
      return this.$store.getters["attendance/allAttendanceRecord"];
    },
  },

  methods: {
    async loadInvoiceChart() {
      await this.$store.dispatch("invoice/allInvoiceStat");

      const loadedData = this.allInvoiceStat;
      let labels = ["Due", "Paid", "Pending"];
      let due = loadedData.amount_not_paid;
      let paid = 0;
      let pending = 0;

      loadedData.invoices_with_payment.forEach((invoice) => {
        const amount = invoice.gross_amount_in_cent / 100;
        !invoice.payment ? (due += amount) : true;
        if (invoice.payment) {
          const status = invoice.payment.status;
          if (status === "created") {
            due += amount;
          }
          if (
            status === "verified" ||
            status === "authorised" ||
            status === "captured"
          ) {
            paid += amount;
          }
          if (status === "failed") {
            pending += amount;
          }
        }
      });

      this.invoiceChartData = {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Invoice status",
              data: [due, paid, pending],
              backgroundColor: [
                "rgb(255, 205, 86)",
                "rgb(54, 162, 235)",
                "rgb(255, 99, 132)",
              ],
              hoverOffset: 4,
            },
          ],
        },

        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: "Payment status",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      };
    },

    async loadAttendanceChart() {
      await this.$store.dispatch("attendance/allAttendanceRecord");

      const loadedData = this.allAttendanceRecord;
      let labels = ["Attendance Breakup"];
      let present = parseInt(loadedData.today.present);
      let absent = parseInt(loadedData.today.absent);
      let total = parseInt(loadedData.total_students);
      let not_called = total - (present + absent);

      this.attendanceChartData = {
        data: {
          labels: labels,
          datasets: [
            {
              type: "bar",
              label: "Not Called",
              data: [not_called],

              backgroundColor: "rgb(255, 205, 86, 0.7)",
              borderColor: "rgb(255, 205, 86)",
              borderWidth: 2,
            },
            {
              type: "bar",
              label: "Present",
              data: [present],

              backgroundColor: "rgb(54, 162, 235, 0.7)",
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 2,
            },
            {
              type: "bar",
              label: "Absent",
              data: [absent],

              backgroundColor: "rgb(255, 99, 132, 0.7)",
              borderColor: "rgb(255, 99, 132)",
              borderWidth: 2,
            },
          ],
        },

        options: {
          responsive: true,
          scales: {
            x: {
              ticks: {
                display: false,
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Daily Attendance",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      };
    },
    async loadCardData() {
      await this.$store.dispatch("session/resources", { refresh: true });
      await this.$store.dispatch("class/resources", { refresh: true });
      await this.$store.dispatch("teacher/resources", { refresh: true });
      await this.$store.dispatch("student/resources", { refresh: true });
    },

    async initInvoiceChart() {
      await this.loadInvoiceChart();
      const ctx = document.getElementById("invoice-chart");
      new Chart(ctx, this.invoiceChartData);
    },

    async initAttendanceChart() {
      await this.loadAttendanceChart();
      const ctx = document.getElementById("attendance-chart");
      new Chart(ctx, this.attendanceChartData);
    },
  },

  created() {
    this.loadCardData();
    this.initInvoiceChart();
    this.initAttendanceChart();
  },
};
</script>

<style scoped>
</style>
